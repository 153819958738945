.header {
  background-color: #0a1e3c;

  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 84px;
  padding-left: 10em;
  padding-right: 10em;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.logo {
  font-size: 24px;
  color: #049dfe;
  cursor: pointer;
}

.menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menu-mobile {
  display: flex;
  position: fixed;
  left: -200px;
  top: 0;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: #0a1e3c;
  transition: left 0.3s;
  z-index: 9999;
  width: 200px;
}

.menu-mobile-open {
  left: 0;
}

.menu-link {
  color: #ffffff;
  font-weight: bold;
  margin: 0 15px;
  cursor: pointer;
  text-decoration: none;
}

.menu-link:hover {
  color: #049dfe;
}

.cta-button {
  background-color: #00af4f;
  border: none;
  color: #ffffff;
  font-weight: bold;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
  z-index: 10000; /* Add this line */
}

.cta-button:hover {
  background-color: #ffffff;
  color: #0a1e3c;
}

.hamburger {
  display: none;
  font-size: 24px;
  color: #ffffff;
  cursor: pointer;
}

@media (max-width: 1200px) {
  .menu {
    display: none;
  }

  .hamburger {
    display: block;
  }
}
@media (max-width: 1024px) {
  .menu-link {
    padding-bottom: 2em;
  }
}

@media (max-width: 950px) {
  .header {
    padding-left: 20px;
    padding-right: 20px;
  }
}
