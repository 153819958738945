.contacto-container {
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60em auto;
  padding-top: 5em;
  padding-bottom: 20em;
  display: flex;
  justify-content: center;
  align-content: baseline;
  margin: 2em;
}

.contacto-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

@media (max-width: 768px) {
  .contacto-content {
    position: absolute;
    top: 50%;
    left: 25%;
    transform: translate(-25%, -50%);
    text-align: center;
    color: white;
  }
}
.contacto-button {
  margin-top: 20px;
  transition: background-color 0.3s, border-color 0.3s;
  background-color: #00af4f;
  border-color: #00af4f;
}

.contacto-button:hover {
  background-color: #00af4f;
  border-color: #049dfe;
}

.flex-grid-contact {
  display: flex;
}

.col-contact {
  flex: 1;
  text-align: left;
}
.col-contact h1 {
  width: 50%;
}
@media (max-width: 1024px) {
  .contacto-container {
    background-size: cover;
    padding-top: 0;
    margin: 0em;
  }
  .col-contact h1 {
    width: 50%;
    font-size: 2em;
  }
}
@media (max-width: 768px) {
  .contacto-container {
    background-size: cover;
    padding-top: 0;
    margin: 0em;
  }
  .col-contact h1 {
    width: 50%;
    font-size: 1.5em;
  }
}
@media (max-width: 425px) {
  .col-contact h1 {
    width: 90%;
  }
  .contacto-container {
    margin: 0em;
  }
}
