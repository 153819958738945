.funcionalidades {
  width: 90%;
  padding-top: 5em;
  margin: 0 auto;
  position: relative;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #363636 !important;
  font-weight: 500;
}

.ant-tabs-ink-bar {
  border-bottom: 4px solid #0a1e3c;
}
.image-col {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.text-col {
  padding-top: 1em;
}
.text-col p {
  width: 75%;
}
.text-col h1 {
  width: 50%;
}
.text-mobile {
  display: flex;
  flex-direction: column;
}

.custom-tabs-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 48px;
  height: 48px;
  font-size: 128px;
  color: #00af4f;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  z-index: 1;
  transition: background-color 0.3s;
}
.custom-tabs-arrow-m {
  display: flex;
  justify-content: center;
  font-size: 32px;
  color: #00af4f;
  cursor: pointer;
}

.custom-tabs-arrow:hover {
  background-color: #f0f0f0;
}

.custom-tabs-arrow.left {
  left: 0px;
}

.custom-tabs-arrow.right {
  right: 0px;
}

.description {
  padding-left: 2em;
  color: gray;
}

.description-mobile {
  color: gray;
}
