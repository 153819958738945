.testimonios-background {
  background-color: #fff;
  padding: 100px 0;
}

.testimonios-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.testimonios-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1em;
}

.testimonial-card {
  width: 100%;
  max-width: 350px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  text-align: left;
}
.testimonial-card small {
  color: gray;
}

.star-rating {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
@media (max-width: 1024px) {
  .testimonios-container {
    justify-content: center;
  }
}

.show-more {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer;
}
