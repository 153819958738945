.big-banner {
  background-size: cover;
  background-position: top;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  overflow: hidden;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0 100%);
}

.big-banner-content {
  padding-left: 6vw;
  padding-right: 50%;
  text-align: left;
  position: relative;
  z-index: 1;
  max-width: 100%;
}

.big-banner-content h1 {
  font-size: 3em;
  font-weight: bold;
  margin-bottom: 20px;
  width: 80%;
  color: #0a1e3c;
}

.big-banner-content p {
  font-size: 1.5em;
  margin-bottom: 30px;
  width: 75%;
  color: #0a1e3c;
}
@media only screen and (max-width: 1024px) {
  .big-banner {
    background-position: bottom;
  }
  .big-banner-content h1 {
    font-size: 2em;
  }
}
@media only screen and (max-width: 768px) {
  .big-banner-content h1 {
    font-size: 1.5em;
  }

  .big-banner-content p {
    font-size: 1em;
  }
}

@media only screen and (max-width: 425px) {
  .big-banner-content h1 {
    font-size: 1.35em;
  }

  .big-banner-content p {
    font-size: 0.9em;
  }
  .big-banner {
    background-position: center;
  }
}
@media only screen and (max-width: 320px) {
  .big-banner-content h1 {
    font-size: 1.3em;
  }

  .big-banner-content p {
    font-size: 0.75em;
  }
}

.easy-control-btn {
  background-color: #00af4f;
  border: none;
  color: #fff;
  font-weight: bold;
  padding: 12px 24px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}

.easy-control-btn:hover {
  background-color: #0a1e3c;
  color: #fff;
}
