.precios-wrapper {
  background-color: #0a1e3c;
  clip-path: polygon(0 0%, 100% 2.5%, 100% 100%, 0 95%);
  padding-top: 100px;
  padding-bottom: 100px;
}
.precios-container {
  display: flex;
  justify-content: center;
  padding: 50px 0;
}

.custom-card {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 50px 0;
}

@media (max-width: 950px) {
  .custom-card {
    width: 100%;
    max-width: none;
    padding: 0 20px; /* Add some padding on smaller screens */
  }
}
@media (max-width: 320px) {
  .custom-card {
    width: 100%;
    max-width: none;
    padding: 0 0px; /* Add some padding on smaller screens */
  }
}

.price-row {
  display: flex;
  align-items: center;
  padding: 0px; /* reduce padding between rows */
  border-bottom: 1px solid #f0f2f5; /* add lines between rows */
}

.price-row:first-child {
  border-top: 1px solid #f0f2f5; /* add line on top of the first row */
}

.price-row:last-child {
  border-bottom: none; /* remove line from the last row */
}

.price-row.header {
  font-weight: bold;
  background-color: #f0f2f5;
}

.plan-col {
  display: flex;
  justify-content: center;
}
