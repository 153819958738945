.whatsapp-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 64px;
  color: #25d366;
  z-index: 1000;
}

.whatsapp-icon-offset {
  bottom: 180px;
}

.whatsapp-icon:hover {
  color: #00a33c;
}
