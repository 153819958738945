.footer {
  background-color: #0a1e3c;
  color: #ffffff;
  padding: 30px 20px;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.footer-logo {
  font-size: 24px;
  color: #049dfe;
  cursor: pointer;
}

.footer-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-links a {
  color: #ffffff;
  font-weight: bold;
  margin: 0 15px;
  cursor: pointer;
  text-decoration: none;
}

.footer-links a:hover {
  color: #049dfe;
}
.footer-links-mobile a {
  color: #ffffff;
  font-weight: bold;
  font-size: 0.6em;
  margin: 0 15px;
  cursor: pointer;
  text-decoration: none;
}

.footer-links-mobile a:hover {
  color: #049dfe;
}

.social-icons {
  display: flex;
  align-items: center;
}
.social-icons-mobile {
  display: flex;
  padding-top: 1em;
}
.social-icon {
  font-size: 24px;
  margin: 0 8px;
  cursor: pointer;
}

.social-icon:hover {
  color: #049dfe;
}

.footer-copyright {
  text-align: center;
  font-size: 14px;
}
@media (max-width: 375px) {
  .footer-content {
    justify-content: center;
  }
}
